.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

@media screen and (max-width: 640px) {
  .grid {
    margin: 0 auto;
    justify-content: center;
  }
}
