.card {
  background: var(--card-color);
  border-radius: 0.75rem;
  /* overflow: hidden; */
  max-width: 300px;
  position: relative;
}

.card::after {
  content: '';
  height: 52px;
  width: 78%;
  position: absolute;
  bottom: 0;
  z-index: -1;
  display: block;
  box-shadow: 0 3px 24px rgba(0, 0, 0, 0.24);
  margin: 0 auto;
  left: 0;
  right: 0;
}

.card__header {
  display: flex;
  justify-content: space-between;
  margin: 0 1rem;
  align-items: center;
}

.card__header p {
  margin-bottom: 0;
}

.card__header p:nth-child(2) {
  font-family: 'Circular Std Bold';
  color: #9a9a9a;
  font-size: 0.75rem;
}

.card__body {
  display: block;
  text-align: center;
  font-size: 36px;
  font-family: 'Circular Std Bold';
}

.card__footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 3rem;
}

.card__footer p {
  text-align: center;
  font-family: 'Circular Std Bold';
  font-size: 0.75rem;
}

.card__footer p:nth-child(1) {
  color: #e5bb48;
}
.card__footer p:nth-child(2) {
  color: #f24d7a;
}
.card__footer p:nth-child(3) {
  color: #4fd17f;
}

.card__footer img {
  margin-right: 6px;
}

@media screen and (max-width: 680px) {
  .card {
    min-width: 100%;
    justify-self: center;
  }
}

@media screen and (max-width: 320px) {
  .card {
    min-width: 270px;
    justify-self: center;
  }
}
