.navbar {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4rem;
}

.navbar span {
  font-family: 'Circular Std Bold';
  font-size: 20px;
}

button,
input {
  outline: 0;
}

button {
  border: 0;
  border-radius: 0.75rem;
  background: transparent;
  cursor: pointer;
}

.navbar input {
  font-size: 0.875rem;
  font-family: 'Circular Std Book';
  padding: 9px 2.75rem 8px 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid var(--border-color);
  min-width: 320px;
}

.navbar__search {
  position: relative;
  /* border: 1px solid red; */
}

.navbar__search button {
  position: absolute;
  right: 6px;
  top: 6px;
  /* border: 1px solid green; */
}

.navbar__options {
  display: grid;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 640px) {
  .navbar input {
    min-width: 220px;
  }
  .navbar {
    padding: 0 1rem;
  }
  .navbar span {
    display: none;
  }
  .navbar__options {
    column-gap: 0.25rem;
  }
}

@media screen and (max-width: 320px) {
  .navbar input {
    min-width: 200px;
  }
}
